<template>
  <div class="q-pa-xs hidden" style="max-width: 500px; margin-bottom: 100px">
    <q-carousel
      v-model="slide"
      transition-prev="slide-right"
      transition-next="slide-left"
      animated
      swipeable
      padding
      height="250px"
      class="rounded-borders"
      style="overflow: hidden"
    >
      <q-carousel-slide :name="1" class="row no-wrap">
        <div
          class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap"
          v-for="product in products"
          :key="product"
          
        >
          <div class="column q-mb-lg">
            <div>
              <img
                class="rounded-borders cursor-pointer"
                :src="defaultImageApp"
                loading="lazy"
                clickable
              />
            </div>

            <div class="text-subtitle1 text-left q-ml-md">Lorem Ipsum</div>
          </div>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    return {
      slide: ref(1),
    };
  },
  data() {
    return {
      defaultImage: this.defaultImageApp,
      products: {
        hola: this.defaultImageApp,
        hola1: this.defaultImageApp,
        hola2: this.defaultImageApp,
        hola3: this.defaultImageApp,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.q-carousel--arrows-horizontal.q-carousel--with-padding .q-carousel__slide {
  padding-left: 10px;
}

img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0.5rem;
  border: 1px solid black;
}

.q-carousel__slides-container {
  .scroll {
    overflow: hidden;
  }
}

</style>
